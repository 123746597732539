<template>
  <div class="login2">
    <el-form
      v-if="!isLogin"
      label-position="top"
      label-width="80px"
      :model="form"
      status-icon
      ref="form1"
      :rules="rules"
      style="width:300px; margin:0 auto;"
    >
      <el-form-item clas="label" label="用户名" prop="uname">
        <el-input placeholder="请输入用户名" v-model="form.uname" @keyup.enter.native="onSubmit('form1')"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pwd">
        <el-input
          @keyup.enter.native="onSubmit('form1')"
          placeholder="请输入密码"
          v-model="form.pwd"
          show-password
          autoComplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="btn-submit" type="primary" @click="onSubmit('form1')">登录</el-button><br/>
        <el-button class="btn-cancel" @click="onReset('form1')">取消</el-button>
      </el-form-item>
    </el-form>
    <ul v-else>
      <li><h1>登录成功</h1></li>
      <li>{{ successName }}, 欢迎您</li>
      <li><router-link to="/userslist/userslist2">用户信息列表&gt;&gt;</router-link></li>
      <li><a :href="xlsx">考勤数据下载&gt;&gt;</a></li>
      <li><el-button class="btn-back" type="primary" @click="back">返回</el-button></li>
    </ul>
  </div>
</template>
<script>
import users from "../assets/js/apis/users";
import fields from "../assets/js/apis/fields";
export default {
  data() {
    return {
      xlsx:"",
      successName:"",
      isLogin: false,
      form: {
        uname: "",
        pwd: "",
      },
      rules: {
        uname: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
        pwd: [{ required: true, message: "密码不能为空", trigger: "blur" }],
      }
    };
  },
  mounted(){
    fields.getFields(this.$route.path)
    .then(result=>{
      this.xlsx=process.env.NODE_ENV=="development"?result.data.xlsx_dev:result.data.xlsx_prod;
    })
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          users.login(this.form).then((result) => {
            var data = result.data;
            if (data.code == 1) {
              this.isLogin = true;
              sessionStorage.setItem("token", data.token);
              sessionStorage.setItem("user", JSON.stringify(data.data));
            } else {
              this.isLogin = false;
              this.$message({
                message: data.msg,
                type:"error",
                offset:60
              })
            }
            this.successName=data.data.name;
            this.$refs[formName].resetFields();
          });
        } else {
          return false;
        }
      });
    },
    onReset(formName) {
      this.$refs[formName].resetFields();
    },
    back() {
      this.form = {
        uname: "",
        pwd: "",
      };
      this.isLogin = false;
      sessionStorage.clear();
    },
  },
};
</script>
<style>
.login2 .label{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.login2 .btn-submit{
  width:100%;
  height: 38px;
  background: #146DFF;
  border-radius: 4px;
  color:#fff;
}
.login2 .btn-cancel{
  width:100%;
  margin-left:0;
  margin-top:20px;
  color:#146DFF;
  border:0 !important;
}

.login2>ul{
  text-align:center;
}
.login2>ul h1{
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
}
.login2>ul>li:nth-child(2){
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  margin-top:39px;
}
.login2>ul>li:nth-child(3){
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  margin-top:39px;
}
.login2>ul>li>.btn-back{
  margin-top:35px;
  width: 120px;
  height: 38px;
  background: #146DFF;
  border-radius: 4px;
  color:#fff;
}
.login2>ul>li:nth-child(4){
  margin-top:35px;
}
.login2>ul>li>a{
  color: #0052d9;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
</style>