import axios from './network';

export default {
  getFields(path){
    return axios.get("/fields",{
      params:{
        path
      }
    })
  }
}